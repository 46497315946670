@use "src/styles/constants" as *;
@use "src/styles/mixins";

$loginBorderRadius: 8px;

.background {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  column-gap: 10vw;
  background-image: url("https://abe-assets.auditbrain.com/abi/images/login/login_bg0.jpg");
  background-repeat: no-repeat no-repeat;
  background-color: #f4f7fc;
}

.introduction {
  width: 45vw;
  .callout {
    margin-top: 50px;
    border-radius: 32px;
    color: #36455d;
    background-color: #aab7f02e;
    font-size: 26px;
    padding: 40px;
    margin-left: 20px;
    p {
      margin-bottom: 15px;
    }
    .strong {
      font-weight: 700;
    }
  }
}
.login {
  width: 450px;
  align-self: center;
  margin-bottom: 20px;

  border-radius: $loginBorderRadius;
  background-color: #ffffff;
  box-shadow: -3px 3px 2px 0 #ddd;

  .header {
    text-transform: uppercase;
    color: #666;
  }

  .logoSection {
    background-color: #ffffff;

    height: 190px;
    border-top-left-radius: $loginBorderRadius;
    border-top-right-radius: $loginBorderRadius;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-size: $fontSize * 3;
    color: #ffffff;
    font-weight: bold;
    font-family: $fontPrimary;

    border-bottom-width: 2px;
    border-bottom-style: dotted;
    border-bottom-color: var(--primary-green);
  }

  .formSection {
    box-sizing: border-box;

    padding: 10px 30px 20px;

    border-bottom-left-radius: $loginBorderRadius;
    border-bottom-right-radius: $loginBorderRadius;

    min-height: 160px;

    .header {
      text-align: center;
      font-weight: 600;
      font-size: 18px;
      padding: 10px;
    }

    .submitContainer {
      padding-top: 15px;
      box-sizing: border-box;

      > button {
        border-style: solid;
        border-width: 0;
        background-color: var(--primary-green);

        font-size: $fontSize * 1.25;
        line-height: 1.5em;
        color: #ffffff;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-family: $fontPrimary;
        outline: none;

        border-radius: 8px;

        padding: 10px 20px;

        cursor: pointer;

        width: 100%;
        box-sizing: border-box;

        position: relative;

        &:disabled {
          opacity: 0.7;
        }
      }
    }

    .usernameReadOnly {
      font-size: 14px;
      margin-bottom: 10px;
      display: flex;
      gap: 5px;
    }
    .passwordLabel {
      margin-bottom: 5px;
      font-size: 16px;
      color: #999;
    }
    .inputGroup {
      padding-bottom: 10px;
      position: relative;

      &.isLast {
        padding-bottom: 0;
      }

      &.hasIcon {
        > input[type="text"],
        > input[type="password"] {
          padding-left: 20px + 24px;
        }
      }

      .inputIcon {
        position: absolute;
        left: 0;
        top: 10px;

        svg {
          display: block;
          width: 24px;
          height: 24px;

          > path {
            fill: var(--primary-light-grey);

            -webkit-transition: 150ms ease-in fill;
            -moz-transition: 150ms ease-in fill;
            transition: 150ms ease-in fill;
          }
        }
      }

      > input[type="text"],
      > input[type="password"] {
        border-style: solid;
        border-left-width: 0;
        border-right-width: 0;
        border-top-width: 0;
        border-bottom-width: 2px;
        background-color: #ffffff;
        border-color: var(--primary-light-grey);

        font-size: $fontSize * 1.5;
        line-height: 1.5em;
        color: var(--primary-dark-grey);
        font-family: $fontPrimary;
        outline: none;

        padding: 10px 20px;

        width: 100%;
        display: block;
        box-sizing: border-box;

        -webkit-transition: 150ms ease-in border-color;
        -moz-transition: 150ms ease-in border-color;
        transition: 150ms ease-in border-color;

        &::placeholder {
          color: #c3c3c3;
        }

        &:disabled {
          &,
          & + .inputIcon svg {
            opacity: 0.7;
          }
        }

        &:focus {
          border-color: var(--primary-dark-grey);

          & + .inputIcon svg > path {
            fill: var(--primary-dark-grey);
          }
        }
      }
    }

    .successMessage,
    .errorMessage {
      margin-top: 10px;

      display: block;
      width: 100%;
      box-sizing: border-box;
      padding: 10px 20px;

      border-width: 1px;
      border-style: solid;

      font-size: $fontSize * 1.15;
      line-height: 1.5em;
      font-family: $fontPrimary;

      border-radius: 8px;
      line-height: 1em;
    }

    .errorMessage {
      border-color: var(--accent-red);
      color: var(--accent-red);
    }

    .successMessage {
      border-color: var(--accent-green);
      color: var(--accent-green);
    }
  }

  .forgotPassword {
    color: #54989d;
    font-weight: 700;

    text-align: right;
    display: inline-block;
    width: 100%;
  }

  .links {
    display: flex;
    justify-content: space-between;
    a {
      margin: 20px auto 10px;
      flex: 1;
    }
  }
}

@media (max-height: 512px) {
  .background {
    .introduction {
      display: none;
    }
  }
}

@media (max-width: 1200px) {
  .background {
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 20px;

    .introduction {
      width: 90vw;
      font-size: 22px;
    }
    .login {
    }
  }
}

@media (min-width: 901px) and (max-width: 1200px) {
  .background {
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 20px;
    .introduction {
      .callout {
        width: 90vw;
        font-size: 22px;
      }
    }
    .login {
      width: 45vw;
    }
  }
}

@media (min-width: 1366px) {
  .introduction {
    width: 45vw;
    .callout {
    }
  }
  .login {
    width: 30vw;
  }
}

.loginWizard {
  position: relative;
  overflow: hidden;
  counter-reset: wizard;
  width: 60%;
  margin: 12px auto 25px;

  $line-width: 2px;
  $bullet-size: 2em;

  $background-color: #609a9e;
  $foreground-color: white;

  $active-background-color: var(--primary-light-grey);
  $active-foreground-color: #aaa;

  div.step {
    position: relative;
    float: left;
    width: 50%;
    text-align: center;

    &:before {
      counter-increment: wizard;
      content: counter(wizard);
      display: block;
      color: $active-foreground-color;
      background-color: $active-background-color;
      border: $line-width solid #dcdcdc;
      text-align: center;
      width: $bullet-size;
      height: $bullet-size;
      line-height: $bullet-size;
      border-radius: $bullet-size;
      position: relative;
      left: 50%;
      margin-bottom: 0;
      margin-left: $bullet-size * -0.5;
      z-index: 1;
    }

    &.active:before {
      background-color: $background-color;
      color: $foreground-color;
      border-color: $foreground-color;
    }
  }

  > div.step {
    &:after {
      content: "";
      display: block;
      width: 100%;
      background-color: $active-background-color;
      height: $line-width;
      position: absolute;
      left: -50%;
      top: calc($bullet-size / 2);
      z-index: 0;
    }
  }

  > div:last-child:after {
    content: "";
    display: block;
    width: 25%;
    background-color: $active-background-color;
    height: $line-width;
    position: absolute;
    right: 0;
    top: calc($bullet-size / 2);
    z-index: 0;
  }

  div.active:after,
  div.active + div:last-child:after {
    background-color: $background-color;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  color: #999;
  font-size: 16px;
  margin-bottom: 10px;
  .col1 {
    display: flex;
    a {
      border-left: 1px solid #999;
      padding: 0 10px;
    }
    a:first-child {
      border-left: none;
    }
  }
  .col2 {
    display: flex;
    gap: 20px;
    .socialnets {
      display: flex;
      gap: 5px;
    }
    .copyright {
      text-align: right;
    }
  }
}

@media (max-width: 678px) {
  .footer {
    flex-direction: column;
  }
}

@media (max-height: 512px) {
  .footer {
    position: static;
  }
}
